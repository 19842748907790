import React from "react";

function Footer() {
  return (
    <div id="footer" className="footer_wrapper">
      <div className="d-flex justify-content-center col-12 m-auto pt-24">
        <div className="pt-24 pb-24 px-16">
          <div>
            <p>Säkerhetskonsulterna Sverige AB</p>
          </div>
          <div>
            <div className="xs-col md-row">
              <p className="color-link-white">
                <a href="mailto:info@sakerhetskonsulterna.com">
                  info@sakerhetskonsulterna.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <p className="fs-12 ">
          © 2022 Säkerhetskonsulterna Sverige AB. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
