import React, { useState, useEffect }  from "react";
import SK_logo_white from "../../../utils/images/SKsta_30light-gray.png";
import { motion } from "framer-motion";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";

const JoinUS = () => {
  const [context, setContext] = useState([]);
  const path = "context/Hi5nt78ZTJWEpqT97lp2/companion";
  useEffect(() => {
    const customerColletctionRef = collection(db, path);
    const unsub = onSnapshot(customerColletctionRef, (snapShot) => {
      let list = [];
      snapShot.docs.forEach((doc) => {
        list.push({ ...doc.data(), id: doc.id });
      });

      setContext(list);
    });
    return () => {
      unsub();
    };
  }, []);

  console.log(context);
  return (
    <div>
      <motion.div
        className="z-i-3"
        initial={{
          width: "100vw",
          height: "100vh",
          bottom: "0vh",
          position: "absolute",
          opacity: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(204 204 204)",
        }}
        transition={{ duration: 2 }}
        animate={{
          bottom: ["0vh", "0vh", "0vh", "100vh"],
          opacity: [1, 1, 1, 0],
        }}
      >
        <h2>BLI VÅR KOLLEGA</h2>
      </motion.div>
      <div className="d-flex justify-content-center services-hero relative">
        <div className="dark-filter absolute col-12 z-i-2" />
        <div className="faded-logo">
          <img src={SK_logo_white} className="w-100" alt="SK logo faded" />
        </div>
        <div className="col-12 d-flex justify-content-center align-items-center flex-column color-white z-i-2">
          <div className="xs-col xs-align-center md-row md-align-start">
            <h1 className="u-fs-uppercase mb-0">Bli vår kollega</h1>
          </div>
        </div>
      </div>
      {context.length > 0 && (
      <div className="section">
        <div className="section-container">
          <h2>{context[5].value}</h2>
          <p className="fs-14 fi">
          {context[1].value}{" "}
          </p>
          <p className="fs-14 fi">{context[2].value}</p>
          <p className="fs-14 fi">{context[4].value}</p>
          <p className="fs-14 pt-24">{context[3].value}</p>
          <p className="fs-14">{context[6].value}</p>
          <p className="fs-14 color-link-black">
            <a href="mailto:claes@sakerhetskonsulterna.com">
            {context[0].value}
            </a>
          </p>
        </div>
      </div>
      )}
    </div>
  );
};

export default JoinUS;
